@import "src/styles/variables";

.head {
  text-align: center;
  height: 535px;
  position: relative;

  .headContent {
    width: 1200px;
    margin: 0 auto;
    overflow-x: visible;
    text-align: left;

    .title {
      display: inline-block;
      vertical-align: bottom;
      padding-bottom: 92px;
      h1 {
        display: block;
      }
    }
    .sticker {
      position: relative;
      display: inline-block;
      vertical-align: top;
      width: 0;
      height: 565px;

      img {
        position: absolute;
      }
    }
  }

  &:before {
    position: absolute;
    content: "";
    left: -308px;
    top: -337px;
    width: 1048px;
    height: 757px;
    opacity: 0.3;
    background: #4A80F9;
    filter: blur(439px);
    z-index: -1;
  }

  &:after {
    position: absolute;
    content: "";
    right: -78px;
    top: 203px;
    width: 849px;
    height: 724px;
    opacity: 0.4;
    background: #C1F2FF;
    filter: blur(255px);
    z-index: -1;
  }

  &>img {
    margin-left: 112px;
  }

  .title {
    text-align: left;

    h4 {
      margin-bottom: 12px;
    }
  }

  .input {
    @include input;
    width: 548px;
  }

}

.detailBlock {
  width: 373px;
  @include section-desc;
}

@include common-section;

.mt36 {
  margin-top: 36px;
}

@include detail-container;

.detailContainer {
  height: 630px;
}
