$btn-bg-color: linear-gradient(111deg, #0076FD 0%, #004EFC 100%), #004EFC;
$primary-color: #004EFC;
$text-color: #15181D;

@mixin color-text {
  font-variation-settings: "opsz" invert;
  font-feature-settings: "kern" on;
  background: linear-gradient(83deg, #8136FF 0%, #2D4AFF 21%, #3171FF 68%, #4AAAFF 89%, #40FFF8 99%);
  -webkit-background-clip: text;
  color: transparent;
  display: inline-block;
}

@mixin color-border {
  border: double transparent;
  border-width: 2px;
  background-image: linear-gradient(white, white),
    linear-gradient(275deg, #7D38FF -1%, #4C43FF 14%, #3060FF 46%, #3171FF 81%, #42EEFA 108%);
  box-shadow: 0 10px 77px 0 rgba(38, 0, 252, 0.06);
  background-clip: content-box, border-box;
  background-origin: border-box;
}

@mixin input {
  margin: 50px 0;
  cursor: pointer;
  @include color-border;
  height: 56px;
  border-radius: 13px;
  display: inline-block;
  background-color: #fff;
  text-align: left;
  border-width: 2px;

  .content {
    line-height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 8px;
  }

  .tips {
    padding-left: 10px;
    @include color-text;
    font-weight: 500;
    vertical-align: top;
  }

  .placeholder {
    font-size: 18px;
    color: #858A99;
    margin-left: 9px;
  }

  .btn {
    padding: 0 18px;
    img {
      vertical-align: sub;
      margin-right: 5px;
    }
  }
}

@mixin section-desc {
  text-align: left;
  display: inline-block;
  h3 {
    @include color-text;
    font-size: 34px;
    font-weight: 900;
  }

  h3 + desc {
    margin-top: 16px;
  }

  ul, li {
    list-style: none;
  }

  ul {
    margin-top: 8px;
    margin-bottom: 28px;
    &>desc {
      margin-top: 16px;
    }
  }

  li {
    margin-top: 12px;
    h4 {
      font-size: 18px;
      font-weight: 500;
      svg {
        margin-right: 10px;
        color: $primary-color;
      }
    }
    &>desc {
      padding-left: 25px;
      margin-top: 6px;
    }
  }

  desc {
    display: block;
    font-size: 18px;
    color: #424A57;
  }
}

@mixin common-section {
  .commonSectionA,
  .commonSectionB {
    text-align: center;
    padding: 60px 0 30px;
    width: 100vw;
    min-width: 1440px;
    overflow-x: hidden;
  }

  .commonSection0 {
    text-align: center;
    width: 100vw;
    min-width: 1440px;
    overflow-x: hidden;
    background: linear-gradient(180deg, transparent 0%, #fff 96%);
  }
}


@mixin detail-container {
  .detailContainer {
    width: 1200px;
    margin: 0 auto;
    position: relative;
    text-align: left;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .detailBlock {
      @include section-desc;
    }
  }

  .oversizeImg {
    position: relative;
    overflow-x: visible;
    width: 800px;
    align-self: flex-start;

    img {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
    }
  }
}

@mixin nav-block {
  .block {
    text-align: left;
    display: flex;
    li {
      flex: 1;
      max-width: 338px;
      min-width: 256px;
      padding: 24px 20px;
      border: 1px solid #E9EBEE;
      background-color: #fff;
      h5 {
        font-weight: normal;
        font-size: 22px;
        margin-bottom: 6px;
      }
      h6 {
        font-size: 13px;
      }
      &+li {
        margin-left: 24px;
      }
    }
    li.active {
      border: 1px solid transparent;
      background: $btn-bg-color!important;
      color: #fff;
    }
  }
}
