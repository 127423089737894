@import "src/styles/variables";

.nav {
  display: inline-block;
  width: calc(100% - 120px);
  padding-left: 110px;
  z-index: 1;

  a, a:hover, a:active {
    color: #15181D;
  }

  li {
    position: relative;

    .popup {
      position: absolute;
      background-color: #fff;
      z-index: 10;
      border-radius: 10px;
      display: none;
      width: fit-content;
      padding: 8px;
      top: 98%;
      left: -10px;

      a {
        display: block;
        padding: 8px 24px;
        &:hover {
          background-color: #E4E5E7;
        }
      }
    }

    &:hover {
      .popup {
        display: block;
      }

      .navItem {
        position: relative;
        &:before {
          position: absolute;
          content: "";
          width: 24px;
          height: 2px;
          top: 0;
          left: calc(50% - 12px);
          background-color: $primary-color;
        }
      }
    }
  }
}

.downloadContainer {
  position: relative;
  .popup {
    position: absolute;
    background-color: #fff;
    z-index: 10;
    border-radius: 10px;
    display: none;
    width: fit-content;
    padding: 8px;
    top: 120%;
    right: -24px;
    box-shadow: 0 6px 30px 0 rgba(0, 0, 0, 0.05);
  }
  &:hover {
    .popup {
      display: block;
    }
  }
}

.navItem {
  padding-top: 22px;
}

.active {
  .navItem {
    position: relative;
    &:before {
      position: absolute;
      content: "";
      width: 24px;
      height: 2px;
      top: 0;
      left: calc(50% - 12px);
      background-color: $primary-color;
    }
  }
}

.xianjianSection {
  text-align: center;
  padding: 60px 0;
  position: relative;
  height: 256px;
  overflow: hidden;
  h2 {
    font-size: 36px;
    font-weight: bold;
    margin-bottom: 32px;
    position: relative;
    z-index: 1;
  }

  .links {
    display: inline-block;
    position: relative;
    z-index: 1;
  }

  &:before {
    position: absolute;
    content: "";
    left: -328px;
    top: 166px;
    width: 1048px;
    height: 757px;
    opacity: 0.3;
    background: #4A80F9;
    filter: blur(439px);
  }

  &:after {
    position: absolute;
    content: "";
    right: -227px;
    top: 53px;
    width: 849px;
    height: 724px;
    opacity: 0.6;

    background: #C1FFF9;

    filter: blur(255px);
  }

}

.footerContent {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  .logo {
    margin-bottom: 13px;
    display: block;
  }

  .inlineBlock {
    display: inline-block;
    vertical-align: middle;
    &+.inlineBlock {
      margin-left: 40px;
    }
  }

  .links {
    a {
      h4 {
        display: inline-block;
      }
      &+a {
        margin-left: 32px;
      }
      &:hover {
        color: #fff;
      }
    }
  }
}

.mt15 {
  margin-top: 15px;
}

.mt25 {
  margin-top: 25px;
}

.download {
  text-align: center;
  color: #15181D;
  display: inline-block;

  &+.download {
    margin-left: 24px;
  }

  img {
    width: 88px;
    height: 88px;
    margin-bottom: 10px;
  }
}

.header {
  padding: 0 28px;
  position: sticky;
  top: 0;
  background: #fff;
  box-shadow: 0px 31px 37px 0px rgba(18, 31, 61, 0.05);
  z-index: 50;
}

.modal_wrapper {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10000;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  overflow-x: hidden;
  overflow-y: auto;
  transition: opacity .2s ease-out;
}

.modal_backdrop {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 0;
  background-color: rgba(26, 26, 26, .65);
  transition: background-color .2s ease-out;
}

.modal {
  position: relative;
  z-index: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  max-height: 80vh;
  margin-right: auto;
  margin-left: auto;
  outline: 0;
  box-shadow: 0 5px 20px rgba(26, 26, 26, .1);
  box-sizing: border-box;
  transition: max-height .4s ease;
  margin-top: 18px;
  width: 600px;
}

.modal_inner {
  background: #fff;
  border-radius: 8px;
  .content {
    max-height: calc(100vh - 150px);
    overflow: auto;
  }
  .section_header {
    text-align: center;
    display: inline-block;
    width: 100%;
    position: relative;
    padding: 10px 20px;
    font-weight: 500;
    font-size: 14px;
    border-bottom: 1px solid #eee;
  }
  .section_content {
    display: inline-block;
    width: 100%;
    position: relative;
    padding: 24px;
    line-height: 1.8;
  }
}
.modal_close_button {
  position: absolute;
  top: 0px;
  right: 0px;
  padding: 12px;
  color: #fff;
  cursor: pointer;
}
