@import "src/styles/variables";

@mixin button-base {
  height: 40px;
  border-radius: 7px;
  line-height: 40px;
  padding: 0 22px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
}

.primary {
  @include button-base;
  background: $btn-bg-color;
  color: #fff;
  border: none;
}

.link {
  color: $primary-color;
  border: none;
  background: transparent;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
}

.default {
  @include button-base;
  background-color: #fff;
  color: #130F1E;
  border: 1px solid #E4E5E7;
}

.outline {
  @include button-base;
  background-color: transparent;
  color: $primary-color;
  @include color-border;
  border-width: 1px;
  padding: 0;

  .content {
    padding: 0 22px;
    line-height: 36px;
  }
}

.content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.button +.button {
  margin-left: 16px;
}
