@import "src/styles/variables";

.regModal {
  position: fixed;
  inset: 0;
  background-color: #fff;
  z-index: 10000;
  bottom: 0;

  &:before {
    position: absolute;
    content: "";
    left: -328px;
    top: 166px;
    width: 1048px;
    height: 757px;
    opacity: 0.3;
    background: #4A80F9;
    filter: blur(439px);
    z-index: -1;
  }

  &:after {
    position: absolute;
    content: "";
    right: -227px;
    top: 53px;
    width: 849px;
    height: 724px;
    opacity: 0.6;
    background: #C1FFF9;
    filter: blur(255px);
    z-index: -1;
  }

  .hideBtn {
    text-align: right;
    line-height: 132px;
    padding-right: 55px;

    svg {
      cursor: pointer;
    }
  }

  .content {
    text-align: center;
    .left {
      text-align: left;
      display: inline-block;
      vertical-align: top;
      width: 418px;
      position: relative;
      padding-top: 43px;
      &:before {
        position: absolute;
        top: 0;
        height: 1px;
        width: 100%;
        content: "";
        border: 1px solid;
        border-image: linear-gradient(270deg, #7D38FF -1%, #4C43FF 14%, #3060FF 46%, #3171FF 81%, #42EEFA 108%) 1;
      }

      h2 {
        font-size: 50px;
        @include color-text
      }

      p {
        margin-top: 50px;
        color: #15181D;
        font-size: 16px;
        line-height: 1.6;
      }

      span {
        color: #004EFC;
        cursor: pointer;
        position: relative;

        &:hover {
          &:after {
            display: block;
          }
        }

        &:after {
          position: absolute;
          top: 20px;
          left: -20px;
          display: none;
          width: 104px;
          height: 110px;
          content: url("../../../public/static-official/images/xiniu-service.png")
        }
      }
    }

    .right {
      display: inline-block;
      margin-left: 235px;
      width: 640px;
    }
  }
}

.form{

  text-align: left;

  .title{
    font-size: 18px;
    text-align: left;
    padding-bottom: 15px;
    border-bottom: solid 1px gainsboro;
    display: flex;
    align-items: center;
    img{
      margin-right: 10px;
    }
  }

  /*小三角*/
  .rectangle {
    content: "";
    width: 0;
    height: 0;
    border: 8px solid;
    border-color: transparent transparent #4A4B4B transparent;
    position: absolute;
    left: 400px;
    z-index: 1;
  }

  .square{
    width: 104px;
    height: 104px;
    background-color: #4A4B4B;
    position: absolute;
    z-index: 1;
    left: 355px;
    top: 105px
  }

  .QRCode{
    width: 90px;
    height: 90px;
    margin-top: 7px;
  }

  .submitted{

    .row2 {
      margin-top: 20px;
      margin-bottom: 12px;
    }

    .row3{
      color: #858A99;
    }

    a,a:hover{
      color: #000;
      cursor: default;
    }

    .close{
      margin-top: 36px;
    }
  }

  .hint{
    color: #999;
    font-size: 13px;
  }


  .tip{
    color: #D54941;
    font-size: 13px;
    text-align: left;
    a{
      color: #028F76;
      margin-left:2px;
      cursor: pointer;
    }
  }



  .form_item{
    width: 47%;
    display: inline-block;
    margin-right: 16px;


    .form_left{
      float: left;
      width: 14%;
      height: 35px;
      line-height: 35px;
      position: relative;

      span{
        float: left;
      }

      span:first-child{
        color: #D54941;
        font-size: 25px;
        margin-top: 5px;
        margin-right: 5px;
      }
    }

    .form_right{
      position: relative;

      select{
        width: 100%;
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        border-radius: 4px;
        opacity: 1;
        background: #FFFFFF;
        border: 1px solid #E8E8E8;
        padding: 12px 16px;
        line-height: 20px;
        outline: none;
      }
      select:focus{
        border: solid 1px;
      }
      input{
        border-radius: 4px;
        padding: 12px 16px;
        width: 100%;
        line-height: 20px;
        background: #FFFFFF;
        border: 1px solid #E8E8E8;
      }
      .required{
        border: solid 1px #D54941;
      }
      .showValidate{
        border: solid 1px #D54941;
      }
      .inputValidate{
        width: 100%;
        color: #D54941;
        font-size: 12px;
        text-align: left;
        margin-left: 5px;
        margin-top: 5px;
        position: absolute;
        line-height: 1;
      }

      .input_item2 {
        width: calc(200% + 16px);
      }
      .input_item{
        margin-bottom: 22px;
        position: relative;
        .formatPrompt{
          color: grey;
          margin-left: 0;
          position: relative;
          left: -86px;
          top: 8px;
          padding: 3px 5px;
          cursor: default;
          font-size: 13px;
        }
        .activeClassName{
          position: relative;
          margin-top: 40px;
          height: 180px;
          width: 100%;
          background: #f5f5f5;
        }
      }
    }

    .select {
      border-radius: 4px;
      padding: 2px 16px;
      width: 100%;
      line-height: 20px;
      background: #FFFFFF;
      border: 1px solid #E8E8E8;
      color: #858A99;
    }

  }

  .btn{
    position: relative;
    margin: 20px;
    top: 40px;
    .submitting{
      position: relative;
      display: inline-block;
      width: 200px;
      line-height: 35px;
      background: #666;
      color: #fff;
      cursor: pointer;
      margin-bottom: 20px;
    }
    @keyframes spin {
      0% { transform: rotate(0deg); }
      100% { transform: rotate(360deg); }
    }

    .loader_small {
      border: 5px solid #f3f3f3;
      animation: spin 1s linear infinite;
      border-top: 1px solid #fff;
      border-radius: 50%;
      width: 18px;
      height: 18px;
      line-height: 1;
      position: absolute;
      left: 235px;
      top: 8px;
      z-index: 3;
    }
  }

  .btns{
    display: inline-block;
    width: 100%;
    margin-top: 10px;
    text-align: center;

    .submitBtn,.cancelBtn {
      float: left;
      color: #fff;
      height: 30px;
      line-height: 30px;
      width: 48%;
      margin-left: 2%;
    }
    .submitBtn{
      background: #333;
    }
    .cancelBtn{
      background: #666;
    }
  }

}

.dropzone {
  height: 160px;
  text-align: center;
  border-radius: 4px;
  padding: 10px 0;
  background: #FFFFFF;
  border: 1px solid #E8E8E8;
  position: relative;

  .uploadTip{
    p{
      color: #858A99;
      font-size: 14px;
    }
    span{
      font-size: 13px;
      color: #C4C6CD;
      margin-top: 10px;
    }
  }

  .uploadContent{
    width: 100%;
    top: 0;
    left: 0;
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;

    img{
      max-width: 100% ;
      max-height: 180px ;
    }
    .cardFormatError{
      color: #D54941;
      font-size: 14px;
      // background: yellow;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .cardFormatErrorTip{
      color: #fff;
      background: #D54941;
      width: 20px;
      height: 20px;
      border-radius: 10px;
      margin-bottom: 5px;
    }
  }
}

.confirm_btn{
  font-size: 14px;
}


