@import "src/styles/variables";

.head {
  text-align: center;
  height: 565px;
  position: relative;

  .headContent {
    width: 1200px;
    margin: 0 auto;
    overflow-x: visible;
    text-align: left;

    .title {
      display: inline-block;
      vertical-align: bottom;
      padding-bottom: 85px;
      h1 {
        display: block;
      }
    }
    .sticker {
      position: relative;
      display: inline-block;
      vertical-align: top;
      width: 0;
      height: 565px;

      img {
        position: absolute;
        bottom: 0;
      }
    }
  }

  &:before {
    position: absolute;
    content: "";
    left: -328px;
    top: -392px;
    width: 849px;
    height: 724px;
    opacity: 0.6;
    background: #C1C9FF;
    filter: blur(255px);
    z-index: -1;
  }

  &:after {
    position: absolute;
    content: "";
    right: -87px;
    top: 273px;
    width: 1048px;
    height: 757px;
    opacity: 0.3;
    background: #4A84F9;
    filter: blur(439px);
    z-index: -1;
  }

  .title {
    text-align: left;

    h4 {
      margin-bottom: 12px;
    }
  }

}

@include common-section;

.commonSectionB {
  background-color: #F5F6F9;
  padding: 60px 0 0;
}

.commonSectionA {
  background-color: #fff;
}

@include detail-container;

.detailContainer {
  height: 630px;
}

.detailBlock {
  @include section-desc;
  li a {
    font-size: 16px;
    font-weight: 500;
    margin-left: 20px;
    color: $primary-color;
    span {
      @include color-text;
      display: inline-block;
    }
  }
}

.mt36 {
  margin-top: 36px;
}

.download {
  text-align: center;
  color: #858A99;
  display: inline-block;
  margin-left: 24px;

  img {
    width: 106px;
    height: 106px;
    margin-bottom: 10px;
  }
}

@include detail-container;
