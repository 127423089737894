@import "src/styles/variables";

.content {
  text-align: center;
  padding: 50px 20px 120px;

  .left {
    text-align: left;
    display: inline-block;
    vertical-align: top;
    width: 400px;
    position: relative;
    padding-top: 40px;

    &:before {
      position: absolute;
      top: 0;
      //height: 1px;
      width: 100%;
      content: "";
      //border: 1px solid;
      //border-image: linear-gradient(270deg, #7D38FF -1%, #4C43FF 14%, #3060FF 46%, #3171FF 81%, #42EEFA 108%) 1;
    }

    h2 {
      font-size: 50px;
      @include color-text
    }

    p {
      margin-top: 50px;
      color: #15181D;
      font-size: 16px;
      line-height: 1.6;
    }

    span {
      color: #004EFC;
      cursor: pointer;
      position: relative;

      &:hover {
        &:after {
          display: block;
        }
      }

      &:after {
        position: absolute;
        top: 20px;
        left: -20px;
        display: none;
        width: 104px;
        height: 110px;
        content: url("../../../public/static-official/images/xiniu-service.png")
      }
    }
  }

  .right {
    display: inline-block;
    margin-left: 120px;
    width: 700px;
  }
}