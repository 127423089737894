.content {
  width: 500px;
  margin: 150px auto;
  text-align: center;

  a {
    background: #2C4EB7;
    color: #fff;
    display: inline-block;
    padding: 5px 20px;
    -webkit-text-decoration: none;
    text-decoration: none;
    margin-top: 20px;
  }
}

.bg {
  background: url(https://vip.xiniudata.com/static/image/error/500.png);
  width: 400px;
  height: 212px;
  margin: 0 auto;
}


