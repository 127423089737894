@import "src/styles/variables";

.head {
  text-align: center;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  &:before {
    position: absolute;
    content: "";
    left: -328px;
    top: -392px;
    width: 849px;
    height: 724px;
    opacity: 0.6;
    background: #C1C9FF;
    filter: blur(255px);
    z-index: -1;
  }

  &:after {
    position: absolute;
    content: "";
    right: -87px;
    top: 273px;
    width: 1048px;
    height: 757px;
    opacity: 0.3;
    background: #4A84F9;
    filter: blur(439px);
    z-index: -1;
  }
}

.list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 1224px;
  margin: 0 auto 60px;
}

.card {
  min-height: 410px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 24px;
  margin: 12px;
  width: calc(50% - 24px);
  border-radius: 18px;
  background: #FFFFFF;
  box-sizing: border-box;
  border: 1px solid #E9EBEE;

  .tags {
    display: inline-block;
    background: #fff;
    padding: 0 13px;
    line-height: 28px;
    border-radius: 14px;
    color: #1160F9;
    font-size: 16px;
    font-weight: 900;
    text-align: center;
    font-family: Source Han Serif CN;
    &+.tags {
      margin-left: 10px;
    }
  }

  .desc {
    font-size: 14px;
  }

  .name {
    font-size: 44px;
    font-weight: 900;
    line-height: 1.5;
    font-family: Source Han Serif CN;
  }

  .en {
    font-family: Source Han Serif CN;
    font-size: 14px;
    font-weight: 300;
  }

  .img {
    border-radius: 17px;
    padding: 24px;
    background-size: cover;
    color: #fff;
    margin-bottom: 20px;
  }

  h3 {
    font-size: 24px;
    font-weight: 900;
    margin-bottom: 12px;
  }

  .desc {
    font-size: 16px;
    color: #858A99;
  }

}

