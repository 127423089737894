@import "src/styles/variables";

.viewMoreLink,.viewMoreLink:hover {
  color: $primary-color;
  svg {
    margin-left: 5px;
  }
}

.xiniuChat {
  text-align: center;
  padding: 116px 0;
  position: relative;
  max-width: 100%;
  overflow-x: clip;

  h2 {
    margin-top: 10px;
    font-size: 30px;
    font-weight: bold;
  }

  &:before {
    position: absolute;
    content: "";
    left: -328px;
    top: 166px;
    width: 1048px;
    height: 757px;
    opacity: 0.3;
    background: #4A80F9;
    filter: blur(439px);
    z-index: -1;
  }

  &:after {
    position: absolute;
    content: "";
    left: 818px;
    top: 53px;
    width: 849px;
    height: 724px;
    opacity: 0.6;
    background: #C1FFF9;
    filter: blur(255px);
    z-index: -1;
  }

  .input {
    @include input;
    width: 780px;
  }

}

.hintsItem {
  height: 40px;
  border-radius: 20px;
  background: #fff;
  cursor: pointer;
  border: 1px solid #DFD9E1;
  line-height: 40px;
  padding: 0 20px;
  margin-left: 16px;
  margin-top: 18px;

  svg {
    margin-left: 5px;
    margin-top: -3px;
  }
}

.solutions {
  padding: 60px 0 30px;
  text-align: center;
  background-color: #F5F6F9;

  ul, li {
    list-style: none;
  }

  .detailItemContainer {
    padding: 40px 20px 50px;
  }

  .detailItem {
    text-align: left;
    width: 100%;
    height: 300px;
    padding: 30px;
    border: 1px solid #E9EBEE;
    border-radius: 24px;
    box-shadow: 0 31px 17px 0 rgba(18, 31, 61, 0.05);
    background: linear-gradient(180deg, #F0F1F6 0%, rgba(240, 241, 246, 0) 70%, #fff 100%);

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    h3 {
      display: inline-block;
      @include color-text;
      font-size: 28px;
      font-weight: 900;
    }
    span {
      font-family: DingTalk JinBuTi;
      font-size: 20px;
      color: #C4C6CD;
      margin-left: 16px;
    }

    ul {
      margin-top: 20px;
    }

    li {
      margin-bottom: 10px;
      svg {
        margin-right: 8px;
        color: $primary-color;
      }
      font-size: 14px;
      font-weight: bold;
      color: #424A57;
      desc {
        font-weight: normal;
        margin-left: 8px;
        font-size: 14px;
        color: #858A99;
      }
    }

    .logoList {
      height: 30px;
      line-height: 30px;
      display: flex;
      justify-content: space-between;
      .logo {
        width: 15%;
        text-align: center;
        padding: 0 12px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      img {
        transform: scale(.25);
        transform-origin: center;
      }
    }
  }
}

.trends {
  text-align: center;
  background-color: transparent;
  padding: 50px 100px;

  section {
    text-align: left;
    border-radius: 26px;
    background: #F5F6F9;
    padding: 36px 46px;

    &+section {
      margin-left: 20px;
    }
  }

  h4 {
    font-size: 24px;
    font-weight: bold;
    display: inline-block;
  }
  h4+desc {
    margin-left: 12px;
    font-size: 16px;
    font-weight: 350;
    color: #858A99;
  }

  .flexbox {
    display: flex;
    &+.flexbox {
      margin-top: 20px;
    }
  }
  .mt40 {
    margin-top: 40px;
  }

  .count {
    color: $primary-color;
    font-weight: bold;
    line-height: 1.2;
  }

  .logo {
    border-radius: 12px;
    border: 1px solid #E9EBEE;
    text-align: center;
    width: 66px;
    height: 66px;
    overflow: hidden;
    display: inline-block;
    vertical-align: middle;
    background-color: #fff;
    img {
      max-width: 66px;
      max-height: 66px;
    }
  }

  .desc {
    display: block;
    margin-top: 20px;
    color: #858A99;
    line-height: 24px;
    font-size: 14px;
  }

  h5 {
    margin-top: 4px;
    font-size: 18px;
  }

  .green {
    color: #2BA471;
    font-weight: bold;
    font-size: 15px;
    padding-left: 20px;
    position: relative;

    &:before {
      position: absolute;
      left: 5px;
      top: -4px;
      width: 10px;
      height: 6px;
      content: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGZpbGw9Im5vbmUiIHdpZHRoPSIxMCIgaGVpZ2h0PSI2Ij48cGF0aCBkPSJNMCAwaDEwTDUgNiAwIDBaIiBmaWxsPSIjMkJBNDcxIi8+PC9zdmc+");
    }
  }

  .red {
    color: #D54941;
    font-weight: bold;
    font-size: 15px;
    padding-left: 20px;
    position: relative;
    &:before {
      position: absolute;
      left: 5px;
      width: 10px;
      top: -5px;
      height: 6px;
      content: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGZpbGw9Im5vbmUiIHdpZHRoPSIxMCIgaGVpZ2h0PSI2Ij48cGF0aCBkPSJNMCA2aDEwTDUgMCAwIDZaIiBmaWxsPSIjRDU0OTQxIi8+PC9zdmc+");
    }
  }

  .label {
    color: #858A99;
  }

  .hangye {
    text-align: center;
    margin-top: 20px;
    overflow-x: hidden;
    position: relative;
    width: 100%;
    height: 100%;

    .l {
      position: absolute;
      top: 0;
      left: 50%;
      width: 580px;
      margin: 0 auto;
      transform: translateX(-50%);
    }

    .tag {
      border-radius: 20px;
      background: #F5F6F9;
      border: 2px solid #FFFFFF;
      text-align: center;
      padding: 20px;
      margin: 8px;
      display: inline-block;
      box-shadow: 0px 18px 37px 0px rgba(20, 54, 132, 0.1),inset 0px 0px 22px 0px rgba(20, 54, 132, 0.05);
    }
  }

}

.benchmark {
  text-align: center;
  padding: 64px 30px 20px;
  margin: 0 auto;
  background-color: #F5F6F9;
  h2 {
    margin-bottom: 20px;
  }

  .benchmark1 {
    width: 1228px;
    img {
      margin: 0 auto;
      width: 90%;
    }
  }
}

.mt25 {
  margin-top: 25px;
}

.mt30 {
  margin-top: 30px;
  display: block;
}

.mt48 {
  margin-top: 48px;
  display: block;
}

.prev, .next {
  position: absolute;
  top: 50%;
  display: block;
  padding: 0;
  transform: translate(0, -50%);
  outline: none;
  vertical-align: middle;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  border: 1px solid #E4E6E8;
  cursor: pointer;
  background-color: #fff;
  line-height: 48px;
  z-index: 3;

  &:hover {
    border-color: $primary-color;
  }
}

.prev {
  left: 0;
}

.next {
  right: 0;
}
