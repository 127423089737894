@import "src/styles/variables";

.head {
  text-align: center;
  height: 535px;
  position: relative;

  .headContent {
    width: 1200px;
    margin: 0 auto;
    overflow-x: visible;
    text-align: left;

    .title {
      display: inline-block;
      vertical-align: bottom;
      padding-bottom: 77px;
      h1 {
        display: block;
      }
    }
    .sticker {
      position: relative;
      display: inline-block;
      vertical-align: top;
      width: 0;
      height: 565px;

      img {
        position: absolute;
      }
    }
  }

  &:before {
    position: absolute;
    content: "";
    left: -328px;
    top: -392px;
    width: 849px;
    height: 724px;
    opacity: 0.6;
    background: #C1C9FF;
    filter: blur(255px);
    z-index: -1;
  }

  &:after {
    position: absolute;
    content: "";
    right: -87px;
    top: 273px;
    width: 1048px;
    height: 757px;
    opacity: 0.3;
    background: #4A84F9;
    filter: blur(439px);
    z-index: -1;
  }

  &>img {
    margin-left: 112px;
  }

  .title {
    text-align: left;

    h4 {
      margin-bottom: 12px;
    }
  }

  .input {
    @include input;
    width: 548px;
  }

}

.detailBlock {
  width: 373px;
  @include section-desc;
}

@include common-section;

.mt36 {
  margin-top: 36px;
}

@include detail-container;


.products {
  width: 1200px;
  margin: 0 auto;
  background: linear-gradient(180deg, transparent 0%, #fff 96%);
  text-align: left;

  h3 {
    img {
      margin-right: 12px;
    }
    font-size: 28px;
    font-weight: 900;
    margin-bottom: 10px;
  }

  .detail {
    display: flex;
    align-items: flex-start;
    margin-top: 36px;

    .detailItem +.detailItem {
      margin-left: 16px;
    }

    .detailItem {
      position: relative;
      padding: 32px 28px;
      border-radius: 23px;
      flex: 1;
      background: linear-gradient(180deg, #0073FD 0%, rgba(0, 115, 253, 0) 100%);
      color: #fff;
      min-height: 540px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      overflow-x: hidden;

      p {
        line-height: 2;
      }

      .sticker {
        visibility: visible;
        position: absolute;
        z-index: 0;
        opacity: .3;
        width: 307px;
        height: 307px;
        bottom: 18px;
        right: -98px;
      }
      .img,
      .omit,
      h3>img,
      .tryBtn{
        display: none;
      }
    }

    .detailItemActive {
      position: relative;
      padding: 32px 40px;
      flex: 3;
      background: linear-gradient(180deg, #F6F9FF 0%, rgba(246, 249, 255, 0.69) 100%);
      color: #15181D;
      overflow-x: hidden;

      h3 {
        @include color-text;
        img {
          display: inline-block;
        }
      }

      .sticker {
        visibility: hidden;
      }
      .omit,
      .tryBtn {
        display: block;
      }

      .tryBtn {
        margin-top: 24px;
      }

      .img {
        display: block;
        width: 100%;
        margin-top: 20px;
      }
    }
  }
}

.cardList {
  margin: 60px auto 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 1200px;

  .card {
    @include section-desc;
    width: 586px;
    border-radius: 18px;
    background: #FFFFFF;
    box-sizing: border-box;
    border: 1px solid #E9EBEE;
    padding: 40px 54px;
    display: flex;
    height: 470px;
    justify-content: space-between;
    flex-direction: column;
    margin-bottom: 24px;

    h3 {
      svg {
        margin-right: 16px;
      }
    }

    desc {
      margin: 12px 0 20px;
    }

  }
}
